@import "variables";

.text-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.shading {
  &:hover {
    background-color: #f2f2f2;
  }
}

.shading2 {
  &:hover {
    background-color: #f9f9f9;
  }
}

.underline {
  &:hover {
    text-decoration: underline;
  }
}


.name {
  &-A {
    background: #9b6443 !important;
  }

  &-B {
    background: #985631 !important;
  }

  &-C {
    background: #159413 !important;
  }

  &-D {
    background: #811E08 !important;
  }

  &-E {
    background: #4c7e3e !important;
  }

  &-F {
    background: #3EB07E !important;
  }

  &-G {
    background: #3f8583 !important;
  }

  &-H {
    background: #9944CA !important;
  }

  &-I {
    background: #1B2AC9 !important;
  }

  &-J {
    background: #a48649 !important;
  }

  &-K {
    background: #9734AF !important;
  }

  &-L {
    background: #4f3eb0 !important;
  }

  &-M {
    background: #3c9280 !important;
  }

  &-N {
    background: #E70B7F !important;
  }

  &-Ñ {
    background: #0E18F6 !important;
  }

  &-O {
    background: #417b92 !important;
  }

  &-P {
    background: #a03963 !important;
  }

  &-Q {
    background: #1D49EB !important;
  }

  &-R {
    background: #8a3e62 !important;
  }

  &-S {
    background: #861e37 !important;
  }

  &-T {
    background: #5E054F !important;
  }

  &-U {
    background: #CC3E07 !important;
  }

  &-V {
    background: #4eb2b8 !important;
  }

  &-W {
    background: #51b75b !important;
  }

  &-X {
    background: #8c2b1f !important;
  }

  &-Y {
    background: #732860 !important;
  }

  &-Z {
    background: #a22f48 !important;
  }
}

.vh-80 {
  height: 80vh;
}

.vh-70 {
  height: 70vh;
}

.vh-60 {
  height: 60vh;
}

.mx-vh-60 {
  max-height: 60vh;
}

.vh-35 {
  height: 35vh !important;
}

.vh-50 {
  height: 50vh;
}

.min-vh-35 {
  min-height: 35vh;
}


.height-content-auto {
  height: auto !important;

  .form-control {
    //min-height: calc(1em + 1rem + 5px) !important;
    @extend .height-content-auto;
  }
}

.popover {
  max-width: 600px !important;

  &-body {
    @extend .popover;
  }
}

.clickable {
  cursor: pointer;
}

.custom {
  .dropdown-menu {
    min-width: max-content;
  }

  .btn-secondary {
    color: $secondary !important;
    background: white;

    &:hover {
      background: white;
    }
  }

  &-form-control-search {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
    background: transparent !important;
  }
}


.text-gray {
  color: $gray !important;
}


.avatar {
  color: #fff;
  background-color: #adb5bd;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border-radius: 0.375rem;
  height: 48px;
  width: 48px;
}

.avatar img {
  width: 100%;
  border-radius: 0.375rem;
}

.avatar + .avatar-content {
  display: inline-block;
  margin-left: .75rem;
}

.avatar-xl {
  width: 74px;
  height: 74px;
}

.avatar-lg {
  width: 58px;
  height: 58px;
  font-size: 0.875rem;
}

.avatar-sm {
  width: 36px;
  height: 36px;
  font-size: 0.875rem;
}

.avatar-xs {
  width: 20px;
  height: 20px;
  font-size: 0.75rem;
}

.avatar-group .avatar {
  position: relative;
  z-index: 2;
  border: 2px solid #fff;
}

.avatar-group .avatar:hover {
  z-index: 3;
}

.avatar-group .avatar + .avatar {
  margin-left: -1rem;
}


li, a {
  text-decoration: none !important;
}


.bg-gradient-lighter {
  background: linear-gradient(87deg, #e9ecef 0, #e9eaef 100%) !important;
}

.bg-gradient-light {
  background: linear-gradient(87deg, #adb5bd 0, #adaebd 100%) !important;
}

.bg-translucent-white {
  background-color: rgba(237, 237, 237, 0.6) !important;
}

.bg-gray {
  background: #f2f2f2;
}

.bg-ghostwhite {
  background: ghostwhite;
}

.form-search {
  border: 0 !important;
  transition: all .5s;
  transition: border-bottom-color .5s !important;
  outline: none;

  &:focus {
    outline: none;
    border-bottom: 1px solid #d2d2d2 !important;
  }
}

.modal-auto {
  width: auto;
  max-width: 95% !important;
}

.d-grid {
  display: grid;
}

.grid-auto-column {
  grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
}


.btn-light {
  border: 1px solid #e3e3e3 !important;
}

.h-179 {
  height: 179px;
}


.w-35 {
  width: 35%;
}

.w-65 {
  width: 65%;
}

.w-85 {
  width: 85%;
}


.mr-1 {
  margin-right: 0.25rem !important
}


.my-auto {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important
}

.list-group-item {
  &-active {
    background: $primary;
  }

  &-light {
    background: $white !important;
    color: $dark;

    &:hover {
      background: $light !important;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);

    &:hover {
      color: white
    }
  }
}

.border-night-sky {
  border-color: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
}

.submenu {
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #3862a0;
  height: 0;
  line-height: 40px;
  box-sizing: border-box;
  transition: height 0.3s ease-in-out;
  transition-delay: 0.1s;
}

.zoom-in {
  cursor: zoom-in;
}


.border-gray {
  border-color: gray;
}

.vh-20 {
  height: 20vh;
}