@import "variables";

$size: 13px;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-size: $size;
}

.DateInput_input, .DateInput_input_1 {
  &::placeholder {
    font-size: $size !important;
  }

  font-size: $size;
}

.DateRangePickerInput_arrow_svg {
  width: 15px !important;
  height: 15px !important;
}

.card-footer {
  background-color: white !important;
}

//.css-yk16xz-control,
//.css-1pahdxg-control,
//.css-1fhf3k1-control,
.react-select,
.form-control,
.input-group-text {
  min-height: 38px !important;

  * {
    font-size: 0.88rem !important;
  }

  &__indicator {
    //  position: relative;
    //  width: 30px;
    //
    //  .css-tj5bde-Svg {
    //    position: absolute;
    //    top: 6px;
    //    left: 4px;
    //  }
    //
    @extend .react-select;
  }

  &__control {
    @extend .react-select;
  }
}

.DateInput {
  * {
    font-size: $size;
  }

  .DateInput_input {
    font-size: $size;
  }
}


.progress {
  &-circle {
    transition: background .5s;
    border-radius: 50%;
    width: 50px !important;
    height: 50px !important;
    background: $secondary !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: white;
    cursor: pointer;
    opacity: 10 !important;
    border: 10px solid white;
  }

  &-active {
    background: $primary !important;
  }

  &-circle:hover {
    background: $info;
  }

  &-percentage {
    position: absolute;
    top: -10px;
    right: -10px;
  }

  &-line {
    left: 2.5%;
    width: 95%;
    border: 2px solid #e7e7e7;
    position: absolute;
    z-index: 0;
    margin-top: 5px;
  }

}


th {
  transition: all 0.3s ease-in-out;
}

.dragged-column {
  transform: translate3d(0, 0, 0);
}

.over-column {
  transform: translate3d(0, 0, 0);
}