$primary: #5e72e4;
$primary-light: #6d7dd7;
$secondary: #212529;
$gray: #adadad;


// divors

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: ();

$grays: map-merge(
                (
                        "100": $gray-100,
                        "200": $gray-200,
                        "300": $gray-300,
                        "400": $gray-400,
                        "500": $gray-500,
                        "600": $gray-600,
                        "700": $gray-700,
                        "800": $gray-800,
                        "900": $gray-900
                ),
                $grays
);

//--#{$color}
$brand: #3f6ad8;
$blue: #78C3FB;
$indigo: #444054;
$purple: #794c8a;
$pink: #f65ca2;
$red: #d92550;
$orange: #fd7e14;
$yellow: #f7b924;
$green: #3ac47d;
$teal: #16E0BD;
$cyan: #28b0cd;
$blue-light: #eeeeee;

$primary: $brand;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $blue-light;
$dark: $gray-800;
$focus: $indigo;
$alt: $purple;

$theme-colors: ();

$theme-colors: map-merge(
                (
                        "primary": $primary,
                        "secondary": $secondary,
                        "success": $success,
                        "info": $info,
                        "warning": $warning,
                        "danger": $danger,
                        "light": $light,
                        "dark": $dark,
                        "focus": $focus,
                        "alternate": $alt
                ),
                $theme-colors
);
